<template>
    <v-container class="mt-8">
        <div v-if="!isLading">
            <v-form ref="form" v-model="valid">
                <v-row justify="center">
                    <v-col cols="7">
                        <v-text-field v-model="name" label="姓名" :rules="validateEmpty"></v-text-field>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="7">
                        <v-autocomplete v-model="specialties" :items="specialtyList" :rules="validateArrayEmpty" label="科別"
                            item-text="name" :item-value="item => item" multiple chips></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="7">
                        <v-autocomplete v-model="hcos" :items="hcoList" :rules="validateArrayEmpty" label="醫院"
                            item-text="name" :item-value="item => item" multiple chips></v-autocomplete>
                    </v-col>
                </v-row>
                <!-- <v-row justify="center">
          <v-col cols="7">
            <v-autocomplete
              v-model="productDiseaseTa"
              :items="productDiseaseTaList"
              :rules="validateArrayEmpty"
              label="Product Disease Ta"
              item-text="indication"
              :item-value="item=>item"
              multiple
              chips
            ></v-autocomplete>
          </v-col>
        </v-row> -->
                <!-- <v-row justify="center">
          <v-col cols="7">
            <v-autocomplete
              v-model="permission"
              :items="permissionListList"
              :rules="validateArrayEmpty"
              label="癌別"
              item-text="name"
              :item-value="(item) => item"
              multiple
              chips
            ></v-autocomplete>
          </v-col>
        </v-row> -->
            </v-form>
            <v-row justify="center">
                <v-col cols="7">
                    <v-btn @click="validate"> 送出 </v-btn>
                    <v-btn class="mx-5" @click="clear"> 重置 </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-skeleton-loader v-else class="mx-auto" max-width="300"
            type="article,article,article,article"></v-skeleton-loader>
        <v-snackbar :top="true" v-model="snackbarError" color="error">
            送出失敗請重新送出表單
        </v-snackbar>
        <v-dialog v-model="showDuplicateAlert" scrollable max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">已存在重複姓名資料</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <ol>
                            <li v-for="(doctor, index) in duplicateDoctors" :key="index">
                                {{ doctor.name }}({{ doctor.hco }})
                            </li>
                        </ol>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="showDuplicateAlert = false">
                        取消
                    </v-btn>
                    <v-btn color="red darken-1" text @click="submit">
                        確定新增
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import API from '@/models/api';
export default {
    data() {
        return {
            // datas
            name: undefined,
            specialties: undefined,
            hcos: undefined,
            productDiseaseTa: undefined,
            permission: undefined,
            // lists
            specialtyList: [],
            hcoList: [],
            productDiseaseTaList: [],
            permissionListList: [],
            // form
            valid: true,
            validateEmpty: [v => !!v || '此欄位為必填'],
            validateArrayEmpty: [v => !!v.length || '請選擇至少一項'],
            // loading
            loading: false,
            snackbarError: false,
            // duplicateDoctors
            showDuplicateAlert: false,
            duplicateDoctors: []
        };
    },
    computed: {
        isLading() {
            return (
                !this.specialtyList.length ||
                !this.hcoList.length ||
                !this.productDiseaseTaList.length ||
                !this.permissionListList.length ||
                this.loading
            );
        }
    },
    created() {
        this.fetchSpecialtyList();
        this.fetchHcoList();
        this.fetchProductDiseaseTaList();
        this.fetchPermissionListList();
    },
    methods: {
        async fetchSpecialtyList() {
            try {
                const res = await API.Data.specialtyList();
                this.specialtyList = res.data;
            } catch (e) {
                console.log(e);
            }
        },
        async fetchHcoList() {
            try {
                const res = await API.Data.hcoList();
                this.hcoList = res.data;
            } catch (e) {
                console.log(e);
            }
        },
        async fetchProductDiseaseTaList() {
            try {
                const res = await API.Data.productDiseaseTaList();
                this.productDiseaseTaList = res.data;
            } catch (e) {
                console.log(e);
            }
        },
        async fetchPermissionListList() {
            try {
                const res = await API.Data.permissionList();
                this.permissionListList = res.data;
                // 預設癌別
                this.permission = this.permissionListList;
            } catch (e) {
                console.log(e);
            }
        },
        validate() {
            this.$refs.form.validate();
            console.log('this.valid', this.valid);
            if (this.valid) {
                this.checkHcpDuplicate();
            }
        },
        async checkHcpDuplicate() {
            this.searchHcp(this.name);
        },
        async searchHcp(name) {
            this.loading = true;
            const res = await API.Hcp.search(name);
            let resultData = res.data;
            if (resultData.length > 0) {
                for (let item of resultData) {
                    let doctor = {
                        name: item.name,
                        hco: item.hco
                            .map(item => {
                                return item.hco_id.name;
                            })
                            .join('、')
                    };
                    this.duplicateDoctors.push(doctor);
                }
                this.showDuplicateAlert = true;
            } else {
                this.submit();
            }
            this.loading = false;
        },
        async submit() {
            this.showDuplicateAlert = false;
            let SPE = [];
            let HCO = [];
            const PRO = [];
            const PER = [];

            SPE = this.specialties;
            HCO = this.hcos;

            // this.specialties.forEach((item) => {
            //   SPE.push(item.id);
            // });
            // this.hcos.forEach((item) => {
            //   HCO.push(item.id);
            // });

            // this.productDiseaseTa.forEach((item) => {
            //   PRO.push(item.id);
            // });
            this.permission.forEach(item => {
                PER.push(item.id);
            });

            const SUBMIT = {
                name: this.name,
                specialty: SPE,
                hco: HCO,
                productDiseaseTa: PRO,
                permissions: PER,
                user: window.localStorage.getItem('roche.qr-code.user.email')
                    ? window.localStorage.getItem('roche.qr-code.user.email')
                    : ''
            };
            this.loading = true;

            try {
                const res = await API.Hcp.create(SUBMIT);
                const ID = res.data.id;
                this.$router.push({
                    path: '/qrcode',
                    query: { id: ID, success: true }
                });
            } catch (e) {
                console.log(e);
                this.snackbarError = true;
                this.loading = false;
            }
        },
        clear() {
            this.$refs.form.reset();
        }
    }
};
</script>
